import styled from "styled-components";

import { colorsRGB, IPAD_PRO_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";

export const CardContainer = styled(Container)<{ negativeMargin?: boolean }>`
  flex-direction: column;
  width: 100%;
  margin: ${props => (props.negativeMargin ? "-200px auto auto auto" : "auto")};
  overflow: hidden;
`;

export const AnchoredContainer = styled(Container)`
  flex-direction: column;
  margin: 0;
`;

export const StickyContainer = styled(Container)`
  justify-content: center;
  text-align: center;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  z-index: 5;
  background-color: ${colorsRGB.anchorBC()};

  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    display: none;
  }
`;
