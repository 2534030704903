import React from "react";
import styled from "styled-components";

import { Container } from "@util/standard";
import {
  CardContainer,
  AnchoredContainer,
  StickyContainer,
} from "./shared.styles";
import { Maybe, SanityAnchored } from "@graphql-types";
import { colors, MOBILE_BREAKPOINT } from "@util/constants";
import { AnchoredContent, AnchorLink } from "@global";

const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  transition: 0.5s;
  background-color: ${colors.darkBlue};
  overflow: hidden;
  z-index: 1;
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    display: none;
  }
`;

const FlexContainer = styled(Container)`
  display: flex;
  width: 50%;
  margin: 12px auto;
  justify-items: center;
  align-content: center;
  justify-content: space-evenly;
  z-index: 3;
`;

interface Props {
  data: Maybe<Maybe<SanityAnchored>[]> | undefined;
  baseSlug: string;
  isStudyCase?: boolean;
}

export default function Anchored({ data, baseSlug, isStudyCase }: Props) {
  if (data == null) {
    return null;
  }
  const anchors = data.filter(
    anchor => anchor && anchor.anchoredTitle != null
  );
  return (
    <AnchoredContainer>
      <StickyContainer>
        <Overlay />
        <FlexContainer>
          {anchors &&
            anchors.map((anchor) => {
              if (anchor == null) {
                return null;
              }
              return (
                <Container
                  key={anchor?._key}
                >
                  <AnchorLink
                    title={anchor.anchoredTitle}
                    baseSlug={baseSlug}
                    anchor={anchor.anchoredId}
                  />
                </Container>
              );
            })}
        </FlexContainer>
      </StickyContainer>
      <CardContainer>
        {data.map(node => {
          if (node == null) {
            return null;
          }
          const element = node.elements && node.elements[0];

          return element ? (
            <AnchoredContent
              id={node.anchoredId}
              key={element._key}
              data={element}
              isStudyCase={isStudyCase}
            />
          ) : null;
        })}
      </CardContainer>
    </AnchoredContainer>
  );
}
