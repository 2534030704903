import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

import CapabilitiesHero from "@components/capabilities/capabilitiesHero";
import { Query } from "@graphql-types";
import QuoteBlock from "@shared/quoteBlock";
import Anchored from "@shared/anchored";
import SEO from "@shared/seo";

const CapabilitiesPage = () => {

  const { sanityCapabilityPage }: Query = useStaticQuery(graphql`
    {
      sanityCapabilityPage {
        quoteBlock {
          ...sanityColumn
        }
        content {
          _key
          anchoredTitle
          anchoredId
          elements {
            ... on SanityColumn {
              ...sanityColumn
            }
            ... on SanityFullWidthContent {
              ...sanityFullWidthContent
            }
            ... on SanityLeftRightBlock {
              ...sanityLeftRightBlock
            }
          }
        }
          seo {
            ...sanitySeo
        }
      }
    }
  `);

  if (sanityCapabilityPage == null) {
    return null;
  }


  return (
    <>
      <SEO seoData={sanityCapabilityPage?.seo} />
      <CapabilitiesHero />
      <QuoteBlock quoteData={sanityCapabilityPage?.quoteBlock} />
      <Anchored baseSlug="capabilities" data={sanityCapabilityPage?.content} key={sanityCapabilityPage?._key} />
    </>
  );
};

export default CapabilitiesPage;
