import React from "react";
import { useStaticQuery, graphql } from "gatsby";


import Hero from "@shared/hero";
import { Query } from "@graphql-types";

const CapabilitiesHero = () => {
  const { sanityCapabilityPage }: Query = useStaticQuery(graphql`
    {
      sanityCapabilityPage {
          hero {
            ...sanityHero
          }
        }
    }
  `);

  return <Hero data={sanityCapabilityPage?.hero} height="67vh" />;
};

export default CapabilitiesHero;
